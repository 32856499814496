<template>
  <div>
    <b-row>
      <b-col lg="8">
        <p class="content-slogan w-100 m-0">{{ $t('router-info') }}</p>
        <p>{{ $t('router-info-content') }}</p>
        <p><strong class="mr-2">{{ $t('step1') }}</strong>{{ $t('router-info-1') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_9.png" height="512px">
        </div>
        <p><strong class="mr-2">{{ $t('step2') }}</strong>{{ $t('router-info-2') }}</p>
        <div class="text-center">
          <img src="/assets/image/guide/guide_10.jpg" height="512px">
        </div>
        <p><strong class="mr-2">{{ $t('step3') }}</strong>{{ $t('router-info-3') }}</p>
        <p class="font-weight-bold">{{ $t('router-info-4.content') }}</p>
        <ol>
          <li>{{ $t('router-info-4.1') }}</li>
          <li>{{ $t('router-info-4.2') }}</li>
          <ul>
            <li>{{ $t('router-info-4.3.1') }}</li>
            <li>{{ $t('router-info-4.3.2') }}</li>
            <li>{{ $t('router-info-4.3.3') }}</li>
            <li>{{ $t('router-info-4.3.4') }}</li>
            <li>{{ $t('router-info-4.3.5') }}</li>
            <li>{{ $t('router-info-4.3.6') }}</li>
          </ul>
          <li>{{ $t('router-info-4.4') }}</li>
          <li>{{ $t('router-info-4.5') }}</li>
        </ol>
        <div class="text-center">
          <img src="/assets/image/guide/guide_11.jpg" height="512px">
        </div>
        <div class="text-center">
          <img src="/assets/image/guide/guide_12.jpg" height="512px">
        </div>
      </b-col>
      <b-col lg="4">
        <RightMenu />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RightMenu from "@/layout/RightMenu";
export default {
  components: {
    RightMenu,
  },
};
</script>

<style scoped>
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  border-radius: 24px;
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
}
.content-card p {
  height: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-card:hover {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
}
</style>
